module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/opt/build/repo/src/components/templates/LayoutTemplate.js","logo":"/opt/build/repo/src/components/foundations/Logo/Logo.js","home":"/opt/build/repo/src/components/templates/HomeTemplate.js","tour":"/opt/build/repo/src/components/templates/TourTemplate/TourTemplateShared.js","tourPrivate":"/opt/build/repo/src/components/templates/TourTemplate/TourTemplatePrivate.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pompeiify","short_name":"pompeiify","start_url":"/","background_color":"#A22E37","theme_color":"#A22E37","display":"minimal-ui","icon":"src/images/Logo-square.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7cfa65891ce4da9f2c9b875456bee351"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["it","en"],"defaultLanguage":"it","siteUrl":"https://www.pompeiify.com","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-574FSS649Z","cookieName":"rcl_statistics_consent","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"","cookieName":"rcl_marketing_consent","dataLayerName":"dataLayer"},"facebookPixel":{"pixelId":"684187126225997","cookieName":"rcl_marketing_consent"},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
