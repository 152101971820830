/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import Toolbar_ from '@material-ui/core/Toolbar'

export const Toolbar = styled(Toolbar_)`
  justify-content: space-between;
  overflow: hidden;
  padding-left: 0px;
  padding-right: 0px;
`
