import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Container from '@material-ui/core/Container'

import Logo from 'components/foundations/Logo/Logo'
import LanguageSelector from 'components/modules/LanguageSelector/LanguageSelector'

import { makeStyles } from '@material-ui/core/styles'
import { Toolbar } from './Header.styles'

const useStyles = makeStyles(() => ({
  root: {
    '&.MuiPaper-elevation4': {
      boxShadow: `none`,
    },
    '&.MuiToolbar-gutters': {
      paddingLeft: `0px`,
      paddingRight: `0px`,
    },
  },
}))

export default function Header({ cssClass, redBg }) {
  const classes = useStyles()

  return (
    <div id="div-header" className={redBg ? 'pompeii-red-background' : 'white-background'}>
      <AppBar
        className={redBg ? `${classes.root} ${cssClass} ${'red-header'}` : `${classes.root} ${cssClass}`}
        position="sticky"
      >
        <header>
          <Container className={classes.root}>
            <Toolbar>
              <Logo />
              <LanguageSelector isRed={redBg} />
            </Toolbar>
          </Container>
        </header>
      </AppBar>
    </div>
  )
}
