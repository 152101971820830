// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-cancel-policy-js": () => import("./../../../src/pages/cancel-policy.js" /* webpackChunkName: "component---src-pages-cancel-policy-js" */),
  "component---src-pages-capri-faq-js": () => import("./../../../src/pages/capri-faq.js" /* webpackChunkName: "component---src-pages-capri-faq-js" */),
  "component---src-pages-hercolaneum-faq-js": () => import("./../../../src/pages/hercolaneum-faq.js" /* webpackChunkName: "component---src-pages-hercolaneum-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pompeii-faq-js": () => import("./../../../src/pages/pompeii-faq.js" /* webpackChunkName: "component---src-pages-pompeii-faq-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-tours-private-herculaneum-js": () => import("./../../../src/pages/tours/private/herculaneum.js" /* webpackChunkName: "component---src-pages-tours-private-herculaneum-js" */),
  "component---src-pages-tours-private-pompeii-js": () => import("./../../../src/pages/tours/private/pompeii.js" /* webpackChunkName: "component---src-pages-tours-private-pompeii-js" */),
  "component---src-pages-tours-small-group-capri-js": () => import("./../../../src/pages/tours/small-group/capri.js" /* webpackChunkName: "component---src-pages-tours-small-group-capri-js" */),
  "component---src-pages-tours-small-group-herculaneum-js": () => import("./../../../src/pages/tours/small-group/herculaneum.js" /* webpackChunkName: "component---src-pages-tours-small-group-herculaneum-js" */),
  "component---src-pages-tours-small-group-pompeii-js": () => import("./../../../src/pages/tours/small-group/pompeii.js" /* webpackChunkName: "component---src-pages-tours-small-group-pompeii-js" */)
}

