import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { ThemeProvider } from '@material-ui/core/styles'
import { ThemeContextProvider } from 'context/ThemeContext'
import Header from 'components/elements/Header/Header'
import theme from 'theme'

import 'style/layout.scss'

export default function LayoutTemplate({ children }) {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <ThemeProvider theme={theme}>
          <ThemeContextProvider>
            <Header cssClass="h-padding" siteTitle={data.site.siteMetadata.title} />
            <main>{children}</main>
          </ThemeContextProvider>
        </ThemeProvider>
      )}
    />
  )
}
