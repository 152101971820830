import React from 'react'
import { Trans, useI18next } from 'gatsby-plugin-react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { FiChevronDown } from 'react-icons/fi'

const languageName = {
  it: 'Italiano',
  en: 'English',
}

const useStyles = makeStyles(() => ({
  root: {
    '&.MuiButton-root:hover': {
      backgroundColor: `transparent`,
    },
    '&.MuiButton-root': {
      color: `#A22E37`,
    },
    '&.MuiButton-text': {
      padding: `6px 0px`,
    },
  },
}))

const LanguagesSelector = ({ cssClass, isRed }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const classes = useStyles()
  const { t, language, languages, changeLanguage } = useI18next()

  return (
    <>
      <Button
        className={isRed ? `white-color` : `${classes.root} ${cssClass}`}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <FiChevronDown className="chevron chevron-dropdown" />
        <h6>
          <Trans>{t('lng')}</Trans>
        </h6>
      </Button>

      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {languages.map(lng => (
          <MenuItem
            key={lng}
            onClick={() => changeLanguage(lng) > handleClose()}
            style={{
              color: language === lng ? `#DF9D3D` : `#4a4a4a`,
              margin: 10,
              cursor: `pointer`,
            }}
          >
            {languageName[lng]}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default LanguagesSelector
