import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { StaticImage } from 'gatsby-plugin-image'

export default function Logo() {
  return (
    <AniLink paintDrip hex="#ffffff" duration={0.8} to="/">
      <StaticImage className="logo" src="../../../images/Logo.png" layout="constrained" alt="Pompeiify" />
    </AniLink>
  )
}
